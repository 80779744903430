img.blur {
  -webkit-filter: blur(2px) brightness(0.7);
  filter: blur(2px) brightness(0.7);
}

.custom-font-size {
  font-size: 16px;
}

.rdw-link-decorator-icon {
  width: 15px;
  height: 15px;
}

// Added background color for service icons
#custom-dropzone .dropzone__img {
  background-color: #000;
}

// Rmover scroll bar which was displayed without any reason
.rdw-editor-main {
  overflow: unset !important;
}

.detail-page-meta {
  box-shadow: 0px 0px 10px 3px #d6d6d6;
  border-radius: 10px;
  margin: 20px 0px;
  padding: 40px 20px;
}

.vertical-align-middle {
  vertical-align: middle !important;
}
