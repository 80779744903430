.gallery {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  img {
    width: 100%;
  }
}

.gallery__btns {
  @include directify($directions) {
    text-align: directed('left');
  }
  width: 100%;
  margin-bottom: 20px;
}

.gallery__btn {
  background: transparent;
  padding: 0;
  @include directify($directions) {
    #{directed('margin-right')}: 20px;
  }
  text-transform: uppercase;
  color: $color-additional;
  font-size: 12px;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;

  &:focus, &:active {
    outline: none;
  }

  &:hover {
    color: $color-additional-hover;
  }

  &.gallery__btn--active {
    color: $color-accent;
  }
}

.gallery__img-wrap {
  width: 100%;
  overflow: hidden;
  cursor: pointer;
}

@media screen and (min-width: 768px) {

  .gallery__img-wrap {
    width: 50%;
  }
}

@media screen and (min-width: 992px) {

  .gallery__img-wrap {
    width: 25%;
  }
}